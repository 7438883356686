import handleMediaQueryChange from "./_media_query";

// Split Team Name into City + Name
export const teamNameHandler = () => {
    const teamNameContainer = document.querySelector(
        ".zephr-feature_page-title .team-name-container",
    );
    const teamName = teamNameContainer.dataset.teamname;
    const specialTeamNames = [
        "boston red sox",
        "chicago white sox",
        "toronto blue jays",
        "portland trail blazers",
        "detroit red wings",
        "toronto maple leafs",
        "columbus blue jackets",
        "vegas golden knights",
    ];

    if (teamName) {
        for (const teamName of teamName) {
            const splitNames = teamName.innerText.split(' ')
            let lastWord, firstWord

            if(specialTeamNames.includes(teamName.innerText.toLowerCase())) { // City has only 1 word
                firstWord = splitNames.shift()
                lastWord = splitNames.join(' ')
            } else {
                lastWord = splitNames.pop()
                firstWord = splitNames.join(' ')
            }


            if(firstWord && lastWord) {
                teamName.innerHTML =
                `
                    <span class="city-name" data-testid="text--team-city">${firstWord}</span>
                    <span class="team-name" data-testid="text--team-name">${lastWord}</span>
                `
            } else {
                teamName.innerHTML =
                `<span class="team-name" data-testid="text--team-name">${lastWord}</span>`
            }
        }
    }
};

const updateArticleLeadHeight = () => {
    const teamArticleContent = document.querySelector("#teamArticleContent");
    const leadArticleImg = teamArticleContent.querySelector("picture > img");
    const leadArticleContent = teamArticleContent.querySelector(
        ".prologue-image-details",
    );

    if (teamArticleContent) {
        const articleContinerHeight = teamArticleContent.offsetHeight;
        const leadArticleContentHeight = leadArticleContent.offsetHeight;
        leadArticleImg.style.height = `${articleContinerHeight - leadArticleContentHeight}px`;
    }
};

const resetArticleLeadHeight = () => {
    const leadArticleImg = document.querySelector(
        "#teamArticleContent picture > img",
    );
    leadArticleImg.style.height = "auto";
};

export const teamLeadArticleHeight = () => {
    handleMediaQueryChange(resetArticleLeadHeight, updateArticleLeadHeight());
};
